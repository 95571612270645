var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "apprepair" }, [
    _c("div", { staticClass: "apprepair-head" }, [
      _c("ul", [
        _c(
          "li",
          [
            _c("h5", [_vm._v("应用名称")]),
            _vm._v(" "),
            _c("el-input", {
              model: {
                value: _vm.form.name,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "name", $$v)
                },
                expression: "form.name",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("h5", [_vm._v("版本号")]),
            _vm._v(" "),
            _c("el-input", {
              model: {
                value: _vm.form.version,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "version", $$v)
                },
                expression: "form.version",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.search } },
              [_vm._v("查询")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.empty } },
              [_vm._v("清空")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "permit",
                    rawName: "v-permit",
                    value: "/android/store/fixpoint/add",
                    expression: "'/android/store/fixpoint/add'",
                  },
                ],
                attrs: { type: "primary" },
                on: { click: _vm.add },
              },
              [_vm._v("添加")]
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tab" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              border: "",
              "highlight-current-row": "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { type: "index", index: _vm.indexMethod },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "name", label: "应用名称", width: "150px" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "version", label: "版本号", width: "150px" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "lan", label: "语言", width: "100px" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "fixPoint",
                "show-overflow-tooltip": "",
                label: "修复点",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "opUser", label: "操作员", width: "150px" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "opTime", label: "操作时间", width: "200px" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "操作" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        { staticClass: "function-icon" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              directives: [
                                {
                                  name: "permit",
                                  rawName: "v-permit",
                                  value: "/android/store/fixpoint/update",
                                  expression:
                                    "'/android/store/fixpoint/update'",
                                },
                              ],
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "修改",
                                placement: "bottom",
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("../../assets/iconfont/amend.png"),
                                  alt: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.change(scope.row.id)
                                  },
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "page" },
      [
        _c("el-pagination", {
          attrs: {
            "hide-on-single-page": _vm.value,
            "current-page": _vm.currentPage,
            "page-size": 10,
            layout: "total, prev, pager, next",
            total: _vm.total,
            background: "",
          },
          on: { "current-change": _vm.handleCurrentChange },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }