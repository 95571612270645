"use strict";

var _interopRequireWildcard = require("/data/www/prod-cn-snote-manage/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var Quill = _interopRequireWildcard(require("quill"));
var _apprepair = require("../../api/apprepair.js");
var _fs = require("fs");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { quillEditor } from "vue-quill-editor"; //调用编辑器
var _default = exports.default = {
  // components: { quillEditor },
  data: function data() {
    return {
      isDisable: false,
      value: "",
      value1: "",
      value2: "",
      show: "",
      id: "",
      currentPage: 1,
      total: 0,
      tableData: [],
      lanData: [],
      appNameData: [],
      dialogFormVisible: false,
      dialogVisible: false,
      formLabelWidth: "100px",
      loading: false,
      isRepeat: false,
      form: {
        pageNo: 1,
        pageSize: 10,
        appName: "",
        appVersion: ""
      },
      data: {
        appName: "",
        appVersion: "",
        fixPoint: "",
        lan: ""
      },
      updateData: {
        appName: "",
        appVersion: "",
        fixPoint: "",
        lan: ""
      },
      updateRowData: {
        appName: "",
        appVersion: "",
        fixPoint: "",
        lan: ""
      },
      rulesadd: {
        lan: [{
          required: true,
          trigger: "change"
        }],
        appName: [{
          required: true,
          trigger: "change"
        }],
        appVersion: [{
          required: true,
          trigger: "change"
        }],
        fixPoint: [{
          required: true,
          trigger: "blur"
        }]
      },
      editorOption: {
        placeholder: "请输入文本信息...",
        theme: "snow"
      },
      addRange: [],
      Rangeflag: false
    };
  },
  created: function created() {
    this.getList();
  },
  mounted: function mounted() {},
  methods: {
    getList: function getList() {
      var _this = this;
      this.loading = true;
      setTimeout(function () {
        (0, _apprepair.getAppFixpointList)(_this.form).then(function (res) {
          _this.tableData = res.voList;
          _this.total = res.total;
          _this.loading = false;
          _this.isRepeat = false;
        });
      }, 500);
    },
    //添加
    add: function add() {
      this.$router.push({
        path: "addapp"
      });
    },
    //修改
    change: function change(id) {
      this.$router.push({
        path: "changeapp",
        query: {
          id: id
        }
      });
    },
    //查询
    search: function search() {
      var _this2 = this;
      this.loading = true;
      setTimeout(function () {
        (0, _apprepair.getAppFixpointList)({
          pageNo: 1,
          pageSize: 10,
          appName: _this2.form.appName,
          appVersion: _this2.form.appVersion
        }).then(function (res) {
          _this2.tableData = res.voList;
          _this2.total = res.total;
          _this2.currentPage = 1;
          _this2.loading = false;
        });
      }, 500);
    },
    //清空
    empty: function empty() {
      this.form.appName = "";
      this.form.appVersion = "";
      this.getList();
    },
    //获取页码
    handleCurrentChange: function handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    indexMethod: function indexMethod(index) {
      return index + 1;
    },
    handleClick: function handleClick(row) {
      console.log(row);
    }
  }
};
/**
 * 获取当前时间
 */
function getDate() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  var hours = date.getHours(); //获取当前小时数(0-23)
  var minutes = date.getMinutes(); //获取当前分钟数(0-59)
  var seconds = date.getSeconds();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate + " " + hours + ":" + minutes + ":" + seconds;
  return currentdate;
}